<template>
  <div class="filters">
    <Divider type="dashed">
      <b>Categorías</b>
    </Divider>
    <Dropdown v-model="selectedGame" :options="games" optionLabel="name" optionValue="code"
              placeholder="Lotería" class="categorias"
              v-if="page !== 'report-sales' && page !== 'report-conalot'"></Dropdown>

    <Dropdown v-model="selectedGameReport" :options="allgames" optionLabel="name" optionValue="code"
              placeholder="Lotería" class="categorias"
              v-if="page === 'report-sales' || page === 'report-conalot'"
    ></Dropdown>

    <div v-if="page !== 'user'">
      <div>
        <div class="opciones" v-if="page!=='cancel'">
          <Divider type="solid">
            <b>Opciones</b>
          </Divider>
          <!-- Fecha -->
          <Calendar v-model="selectedDate" dateFormat="dd/mm/yy" :showIcon="true"
                    v-if="page !== 'cancel' && page !== 'report-sales' && page !== 'report-conalot'"
                    :onChange="refreshComercializador"/>
          <!-- Periodo-->
          <Calendar v-model="selectedDateRange" dateFormat="dd/mm/yy" :showIcon="true" selectionMode="range"
                    v-if="page === 'report-sales' || page === 'report-conalot'"/>

          <div v-if="page !== 'report-sales' && page !== 'report-conalot' ">
            <!-- Sorteo -->
            <Dropdown v-model="selectedSorteo" :options="sorteos" optionLabel="tproductoxsorteo"
                      placeholder="Sorteo" class="filterItem" :loading="loadingSorteo" :showClear="true"
                      :onClick="refreshSorteo"
                      :filter="true"/>

            <div v-if="selectedGame !== 'animal'">
              <!-- Lista -->
              <Dropdown v-model="selectedLista" :options="listas" optionLabel="tlista" optionValue="id_lista"
                        placeholder="Lista" class="filterItem" :loading="loadingLista"
                        :showClear="page === 'report-sales'"
                        :filter="true"/>
              <!-- Tipo -->
              <Dropdown v-model="selectedTipo" :options="tipos" optionLabel="ttipo_lista" optionValue="id_tipo_lista"
                        placeholder="Tipo" class="filterItem" :loading="loadingTipo"
                        :onClick="refreshTipo"
                        :showClear="true"/>

              <h1 v-if="page === 'lists-online'">
                <!-- Ordenar por -->
                <Dropdown v-model="selectedOrderBy" :options="orderBy" optionLabel="name" optionValue="code"
                          placeholder="Ordenar por" class="filterItem" :showClear="true"/>
              </h1>
            </div>
          </div>
          <div v-else>
            <h1 v-if="page === 'lists-online'">
              <!-- Ordenar por Animalitos -->
              <Dropdown v-model="selectedOrderByAnimalitos" :options="orderByAnimalitos" optionLabel="name"
                        placeholder="Ordenar por" class="filterItem" :showClear="true"/>
            </h1>
          </div>
          <!--    ###########################################################################################################-->
        </div>
        <div v-if="page === 'report-sales' || page === 'report-conalot'">
          <!-- Producto -->
          <Dropdown v-model="selectedProducto" :options="productos" optionLabel="tproducto"
                    placeholder="Producto" class="filterItem" :loading="loadingProducto"
                    :onClick="refreshProducto"
                    :showClear="true"/>
        </div>

        <div v-if="page === 'report-sales'">

          <div v-if="selectedGroupBy === 'modalidad'">
            <!-- Modalidad -->
            <Dropdown v-model="selectedModalidad" :options="modalidades" optionLabel="tmodalidad_pago"
                      placeholder="Perfil de Pago" class="filterItem" :loading="loadingModalidad"
                      :onClick="refreshModalidad"
                      :showClear="true"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="page !== 'report-conalot'">
      <Divider align="left" type="dashed">
        <b>Filtros</b>
      </Divider>
      <!-- Comercializador -->
      <Dropdown v-model="selectedComercializador" :options="comercializadores" optionLabel="tcomercializador"
                placeholder="Bloque" class="filterItem" :loading="loadingComercializador"
                :onClick="refreshComercializador"
                :filter="true"/>
      <!-- Banca -->
      <Dropdown v-model="selectedBanca" :options="bancas" optionLabel="tbanca"
                placeholder="Bancas" class="filterItem" :loading="loadingBanca"
                :showClear="true"
                :onClick="refreshBanca"
                :filter="true"/>
      <!-- Distribuidor -->
      <Dropdown v-model="selectedDistribuidor" :options="distribuidores" optionLabel="tdistribuidor"
                placeholder="Distribuidor" class="filterItem" :loading="loadingDistribuidor"
                :onClick="refreshDistribuidor"
                :showClear="true"
                :filter="true"/>
      <!-- Agencia -->
      <Dropdown v-model="selectedAgencia" :options="agencias" optionLabel="tagencia"
                placeholder="Punto de venta" class="filterItem" :loading="loadingAgencia" :showClear="true"
                :onClick="refreshAgencia"
                :filter="true"
                :key="componentKey"/>

    </div>

    <div v-if="page === 'report-sales' || page === 'report-conalot'">
      <Divider align="left" type="dashed">
        <b>Agrupar por</b>
      </Divider>
      <Dropdown v-model="selectedGroupBy" :options="groupBy" optionLabel="name" optionValue="code"
                placeholder="Agrupar por" class="filterItem"
                :onClick="refreshAgencia"
                :filter="true"/>


      <!--        <div class="field-checkbox">-->
      <!--          <Checkbox id="binary" v-model="displayZeroes" :binary="true" />-->
      <!--          <label for="binary">Mostrar ceros</label>-->
      <!--        </div>-->


    </div>
    <Divider/>
    <div class="action-buttons">
      <Button type="button" icon="pi pi-filter-slash" label="" class="p-button"
              @click="clearFilters()"/>
      <Button class="search-button"
              label="Buscar"
              icon="pi pi-search"
              iconPos="right"
              :disabled="!selectedComercializador"
              :onClick="getData"/>
    </div>

  </div>
</template>

<script>
import MainService from "@/service/backend/MainService";
import Divider from "primevue/divider";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "FilterAnalyst",
  components: {
    'Divider': Divider
  },
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService();
    this.roleId = Number(this.$store.getters.getProfile.role_id);
    this.entityId = Number(this.$store.getters.getProfile.entity_id);
  },
  data() {
    return {
      loading1: false,
      loadingSorteo: false,
      loadingLista: false,
      loadingTipo: false,
      loadingSorteoAnimalitos: false,
      loadingProducto: false,
      loadingModalidad: false,

      loadingComercializador: false,
      loadingBanca: false,
      loadingDistribuidor: false,
      loadingAgencia: false,

      comercializadores: [],
      distribuidores: [],
      bancas: [],
      agencias: [],
      sorteos: [],
      productos: [],
      modalidades: [],
      sorteosAnimalitos: [],
      listas: [{
        "id_lista": 1,
        "tlista": "TRIPLES",

      }, {
        "id_lista": 0,
        "tlista": "TERMINALES",

      }, {
        "id_lista": 3,
        "tlista": "TRIPLES CON SIGNO",

      }, {
        "id_lista": 2,
        "tlista": "TERMINALES CON SIGNO",
      }],
      tipos: [],
      // Options
      selectedDate: new Date(),
      selectedDateRange: [new Date(), new Date()],
      selectedSorteo: null,
      selectedLista: 1,
      selectedTipo: null,
      selectedOrderBy: null,
      selectedAnimalitosSorteo: null,
      selectedOrderByAnimalitos: null,
      // Filters
      selectedDistribuidor: null,
      selectedComercializador: null,
      selectedBanca: null,
      selectedAgencia: null,
      // Group
      selectedGroupBy: 'fecha',

      selectedGame: false,
      selectedGameReport: 'all',
      // Reports
      selectedProducto: false,
      selectedModalidad: false,
      games: [
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      allgames: [
        {name: 'Todos', code: 'all'},
        {name: 'Lotería', code: 'lottery'},
        {name: 'Animalitos', code: 'animal',}
      ],
      orderBy: [{name: 'Número', code: '1'}, {name: 'Monto', code: '2 desc'}],
      orderByAnimalitos: [{name: 'Animalito', code: 'id_elemento_lista'}, {name: 'Monto', code: 'venta desc'}],
      groupBy: [
        {name: 'FECHA', code: 'fecha'},
        {name: 'PRODUCTO', code: 'producto'},
        {name: 'SORTEO', code: 'sorteo'},
        {name: 'PERFIL DE PAGO', code: 'perfil_pago_premios'},
        {name: 'BLOQUE', code: 'comercializador'},
        {name: 'BANCA', code: 'banca'},
        {name: 'DISTRIBUIDOR', code: 'distribuidor'},
        {name: 'PUNTO DE VENTA', code: 'agencia'},
      ],
      componentKey: null,
      displayZeroes: false,
      roleId: null,
    }
  },
  props: {
    page: String,
    conalot: Boolean,
  },
  updated() {
    this.selectedLista = (this.page === 'report-sales' || this.page === 'report-conalot') ? null : this.selectedLista;
  },
  mounted() {
    this.clearFilters();
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
  },
  methods: {
    clearFilters() {
      this.comercializadores = null;
      this.distribuidores = null;
      this.bancas = null;
      this.agencias = null;

      this.sorteos = null;
      this.sorteosAnimalitos = null;
      // this.listas = null;
      this.tipos = null;
      this.productos = null;
      this.modalidades = null;

      this.selectedSorteo = null;
      this.selectedLista = 1;
      this.selectedTipo = null;
      this.selectedComercializador = null;
      this.selectedBanca = null;
      this.selectedDistribuidor = null;
      this.selectedAgencia = null;

      this.selectedGroupBy = 'fecha';

      this.selectedProducto = null;
      this.selectedModalidad = null;

    },

    getData() {
      const dDate = new Date(this.selectedDate);
      const dDateFrom = new Date(this.selectedDateRange[0]);
      const dDateUntil = new Date(this.selectedDateRange[1]);
      this.$emit('get-data', {
        // Category
        category: this.selectedGame,
        reportCategory: this.selectedGameReport,
        // Options
        page: this.page,
        fecha: dDate.getFullYear() + "-" + (dDate.getMonth() + 1) + "-" + dDate.getDate(),
        fechas: [
          dDateFrom.getFullYear() + "-" + (dDateFrom.getMonth() + 1) + "-" + dDateFrom.getDate(),
          dDateUntil.getFullYear() + "-" + (dDateUntil.getMonth() + 1) + "-" + dDateUntil.getDate()],
        sorteo: this.selectedSorteo && this.selectedSorteo,
        lista: this.selectedLista && this.selectedLista,
        tipo_lista: this.selectedTipo && this.selectedTipo,
        orderBy: this.selectedOrderBy && this.selectedOrderBy,
        // Filters
        comercializador: this.selectedComercializador && this.selectedComercializador,
        banca: this.selectedBanca && this.selectedBanca,
        distribuidor: this.selectedDistribuidor && this.selectedDistribuidor,
        agencia: this.selectedAgencia && this.selectedAgencia,
        // Animalitos
        game: this.selectedGame && this.selectedGame,
        sorteoAnimalitos: this.selectedAnimalitosSorteo && this.selectedAnimalitosSorteo,
        orderByAnimalitos: this.selectedOrderByAnimalitos && this.selectedOrderByAnimalitos,
        // Reports
        selectedGroupBy: this.selectedGroupBy && this.selectedGroupBy,
        displayZeroes: this.displayZeroes,
        // Reports
        producto: this.selectedProducto,
        modalidad: this.selectedGroupBy === 'modalidad' ? this.selectedModalidad : null,
      });
    },
    async refreshSorteo() {
      this.loadingSorteo = true;
      this.selectedSorteo = null;
      this.sorteos = await this.mainService.getSorteos({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingSorteo = false;
    },
    async refreshLista() {
      this.loadingLista = true;
      this.listas = await this.mainService.getListas({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingLista = false;
    },
    async refreshTipo() {
      this.loadingTipo = true;
      this.selectedTipo = null;
      this.tipos = await this.mainService.getTipos({
        fecha: this.formatDate2(this.selectedDate),
        game: this.selectedGame
      });
      this.loadingTipo = false;
    },
    async refreshComercializador() {
      this.loadingComercializador = true;
      this.selectedComercializador = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.comercializadores = await this.mainService.getComercializador({
        fecha: this.formatDate2(date),
        comercializador: this.selectedComercializador,
        conalot: this.conalot,
      });
      this.selectedComercializador = this.comercializadores[0];
      this.loadingComercializador = false;
    },
    async refreshBanca() {
      this.loadingBanca = true;
      this.selectedBanca = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.bancas = await this.mainService.getBanca({
        fecha: this.formatDate2(date),
        entity_id: this.entityId,
        comercializador: this.selectedComercializador,
        conalot: this.conalot,
      });
      this.loadingBanca = false;
    },
    async refreshDistribuidor() {
      this.loadingDistribuidor = true;
      this.selectedDistribuidor = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.distribuidores = await this.mainService.getDistribuidor({
        fecha: this.formatDate2(date),
        entity_id: this.entityId,
        comercializador: this.selectedComercializador,
        banca: this.selectedBanca,
        conalot: this.conalot,
      });
      this.loadingDistribuidor = false;
    },
    async refreshAgencia() {
      this.loadingAgencia = true;
      this.selectedAgencia = null;
      const date = this.page === 'report-sales' ? this.selectedDateRange[0] : this.selectedDate;
      this.agencias = await this.mainService.getAgencia({
        fecha: this.formatDate2(date),
        entity_id: this.entityId,
        comercializador: this.selectedComercializador,
        banca: this.selectedBanca,
        distribuidor: this.selectedDistribuidor,
        conalot: this.conalot,
      });
      this.loadingAgencia = false;
    },
    async refreshProducto() {
      this.loadingProducto = true;
      this.selectedProducto = null;
      this.productos = await this.mainService.getProducto({selectedGameReport: this.selectedGameReport});
      this.loadingProducto = false;
    },
    async refreshModalidad() {
      this.loadingModalidad = true;
      this.selectedModalidad = null;
      this.modalidades = await this.mainService.getModalidad({selectedGameReport: this.selectedGameReport});
      this.loadingModalidad = false;
    },
  }
}
</script>

<style scoped lang="scss">
.filters {
  margin-top: 10px;
}

.filterItem {
  margin: 2px 0 5px 0;
}

::v-deep(.categorias) {
  border-color: var(--green-banklot);
  border-width: initial;
  background-color: var(--green-banklot);

  .p-dropdown-label, .p-dropdown-trigger, .p-inputtext {
    color: var(--surface-0);
    font-weight: bold;
  }
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.search-button {
  width: 75%
}

.categorias .p-dropdown-trigger {
  color: white;
}

.p-dropdown .p-component .p-inputwrapper .p-inputwrapper-filled .categorias .p-dropdown-trigger {
  color: white;

}
</style>
